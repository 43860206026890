import * as React from "react";

const VisiteVirtuelleEpode = () => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!4v1681462885375!6m8!1m7!1sCAoSLEFGMVFpcE82Snd1aGtWSHJHV0RDSnRSS1prSlAzWkhjZ0tyZXg3VXNoX1Zx!2m2!1d48.86986640000001!2d2.3189187!3f140!4f0!5f0.7820865974627469" 
      width="600" 
      height="450" 
        style={{ width: "100vw", height: "calc(100vh - 50px)" }}
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"
      title="Visite virtuelle de GHOST GALERIE"
      frameborder="0"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      allowvr="true"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    ></iframe>
    // <iframe
    //   title="Visite virtuelle de GHOST GALERIE"
   
    //   src="https://www.klapty.com/tour/tunnel/YQV0v9USAH"
    //   frameborder="0"
    //   allowfullscreen="true"
    //   mozallowfullscreen="true"
    //   webkitallowfullscreen="true"
    //   allowvr="true"
    //   allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    // ></iframe>
  );
};

export default VisiteVirtuelleEpode;
